<template>
  <div>
    <div class="mt-3">
      <form
        ref="form"
        @submit.stop.prevent="handleEdit"
      >
        <b-form-group
          :label="$t('DirectoryNameUz')"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="form.name_uz"
            required
          />
        </b-form-group>
        <b-form-group
          :label="$t('DirectoryNameRu')"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="form.name_ru"
            required
          />
        </b-form-group>
        <b-form-group
          :label="$t('DirectoryNameEn')"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="form.name_en"
            required
          />
        </b-form-group>
        <b-form-group
            :label="$t('Contact_Number')"
          label-for="name-input"
          invalid-feedback="Number is required"
        >
          <b-form-input
            id="name-input"
            v-model="form.contact_number"
            required
          />
        </b-form-group>
        <b-form-group
            :label="$t('Email')"
          label-for="name-input"
          invalid-feedback="Email is required"
        >
          <b-form-input
            id="name-input"
            v-model="form.email"
            required
          />
        </b-form-group>
      </form>

      <div class="text-right mt-3">
        <b-button
          variant="danger"
          class="mr-2"
          @click="closeManagementEdit"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          variant="success"
          @click="handleEdit"
        >
          {{ $t('Edit') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { editManagement } from '@/api/directories'

export default {
  name: 'MnoEdit',
  props: {
    management: {
      default: null,
    },
  },
  data() {
    return {
      // eslint-disable-next-line vue/no-dupe-keys
      form: {
        name_uz: '',
        name_ru: '',
        name_en: '',
        contact_number: '',
        email: null,
      },
    }
  },
  methods: {
    handleEdit() {
      if (this.form.name_uz
          && this.form.name_ru
          && this.form.name_en
          && this.form.contact_number) {
        // eslint-disable-next-line no-unused-vars
        editManagement(this.form).then(res => {
          this.$emit('editManagement')
        })
      }
    },
    closeManagementEdit() {
      this.$emit('closeManagementEdit')
    },
  },
  watch: {
    management: {
      immediate: true,
      handler(val) {
        this.form = val
      },
    },
  },
}
</script>

<style scoped>

</style>
