/* eslint-disable import/extensions */
<template>
  <div>
    <b-row>
      <b-col md="3">
        <CentreModal @addManagement="managementAdded" />
      </b-col>
      <b-col md="9">
        <div class="input-group mb-3">
          <input
            id="example-search-input"
            class="form-control py-2 border-right-0 border"
            type="search"
            :placeholder="$t('Search')"
            v-model="search"
          >
          <span class="input-group-append">
            <b-button variant="primary" @click="fetchManagement">
              <b-icon icon="search" />
            </b-button>
          </span>
        </div>

      </b-col>
      <div class="w-100">
        <b-card>
          <b-table
              striped
              hover
              :items="management"
              :fields="fields"
              responsive=""
          >
            <template #cell(id)="data">
              <span class="text-md text-primary"> {{ (currentPage-1)*pagination.pageSize+(data.index + 1) }}</span>
            </template>
            <template #cell(action)="data">
              <div class="d-flex">
                <b-button
                    size="sm"
                    variant="warning"
                    @click="handleEdit(data.item)"
                >
                  <b-icon icon="pencil" />
                </b-button>
                <b-button
                    class="ml-1"
                    size="sm"
                    variant="danger"
                    @click="handleDelete(data.item.id)"
                >
                  <b-icon icon="trash" />
                </b-button>
              </div>
            </template>
          </b-table>
          <div class="overflow-auto text-center">
            <b-pagination
                v-model="currentPage"
                align="center"
                :total-rows="total"
                :per-page="pagination.pageSize"
                first-number
                size="lg"
                class="pt-2"
                @input="fetchManagement"
            />
          </div>
        </b-card>
        <b-modal
          id="modal-prevent-closing"
          ref="modal"
          v-model="modalActive"
          :title="$t('Edit')"
          hide-footer
        >
          <MnoEdit
            :management="activeManagement"
            @closeManagementEdit="closeManagement"
            @editManagement="managementEdit"
          />
        </b-modal>
      </div>
    </b-row>
  </div>
</template>

<script>

import { getManagement, deleteManagement } from '@/api/directories'

// eslint-disable-next-line import/extensions
import CentreModal from '@/views/loginpages/moderator/components/mnotypes/center/CentreModal'
// eslint-disable-next-line import/extensions
import MnoEdit from '@/views/loginpages/moderator/components/mnotypes/center/MnoEdit'

export default {
  name: 'MnoCentre',
  components: {
    MnoEdit,
    CentreModal,
  },
  data() {
    return {
      currentPage: 1,
      pagination: {
        pageSize: 15,
      },
      management: [],
      modalActive: false,
      activeManagement: null,
      search: '',
      fields: [
        {
          key: 'id',
          label: this.$t('Id'),
        },
        {
          key: 'name',
          label: this.$t('Name'),
        },
        {
          key: 'contact_number',
          label: this.$t('Contact_Number'),
        },
        {
          key: 'email',
          label: this.$t('Email'),
        },
        {
          key: 'action',
          label: this.$t('Action'),
        },
      ],
      total: 1,
    }
  },
  created() {
    this.fetchManagement()
  },
  methods: {
    fetchManagement() {
      getManagement({ type_id: this.$route.params.id, search: this.search, page: this.currentPage })
        .then(response => {
          this.management = response.data.data.data
          this.total = response.data.data.total
        })
    },
    handleEdit(data) {
      // eslint-disable-next-line no-undef
      this.activeManagement = _.cloneDeep(data)
      this.modalActive = true
    },
    managementEdit() {
      this.modalActive = false
      this.fetchManagement()
    },
    managementAdded() {
      this.fetchManagement()
    },
    closeManagement() {
      this.modalActive = false
    },
    handleDelete(id) {
      if (id) {
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            // eslint-disable-next-line no-unused-vars
            deleteManagement({ id }).then(res => {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Your file has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.fetchManagement()
            })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: 'Your imaginary file is safe :)',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
      }
    },
  },
}
</script>

<style scoped>
p{
  margin-left: 100px;
}
</style>
