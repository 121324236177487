<template>
  <div>
    <b-button
      class="bg-success"
      @click="modalActive=true"
    >
      {{ $t('Add') }}
    </b-button>
    <div class="mt-3">
      <b-modal
        id="modal-prevent-closing"
        ref="modal"
        v-model="modalActive"
        :title="$t('Add')"
        hide-footer
        size="lg"
      >
        <form
          ref="form"
          @submit.stop.prevent="handleSubmit"
        >
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('DirectoryNameUz')"
                label-for="name-input"
                invalid-feedback="Name is required"
              >
                <b-form-input
                  id="name-input"
                  v-model="management.name_uz"
                  required
                />
              </b-form-group>
              <b-form-group
                :label="$t('DirectoryNameRu')"
                label-for="name-input"
                invalid-feedback="Name is required"
              >
                <b-form-input
                  id="name-input"
                  v-model="management.name_ru"
                  required
                />
              </b-form-group>
              <b-form-group
                :label="$t('DirectoryNameEn')"
                label-for="name-input"
                invalid-feedback="Name is required"
              >
                <b-form-input
                  id="name-input"
                  v-model="management.name_en"
                  required
                />

              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('Contact_Number')"
                label-for="name-input"
                invalid-feedback="Name is required"
              >
                <b-form-input
                  id="name-input"
                  v-model="management.contact_number"
                  required
                />
              </b-form-group>
              <b-form-group
                :label="$t('Email')"
                label-for="name-input"
                invalid-feedback="Email is required"
              >
                <b-form-input
                  id="name-input"
                  v-model="management.email"
                  required
                />
              </b-form-group>
            </b-col>
          </b-row>
        </form>
        <div class="text-right mt-3">
          <b-button
            variant="danger"
            class="mr-2"
            @click="modalActive = false"
          >
            {{ $t('Cancel') }}
          </b-button>
          <b-button
            variant="success"
            @click="handleSubmit"
          >
            {{ $t('Add_Management') }}
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { addManagement } from '@/api/directories'

export default {
  name: 'CentreModal',
  data() {
    return {
      modalActive: false,
      management: {
        name_uz: '',
        name_ru: '',
        name_en: '',
        contact_number: '',
        email: null,
      },
    }
  },
  methods: {
    handleSubmit() {
      if (this.management.name_uz
          && this.management.name_ru
          && this.management.name_en
          && this.management.contact_number) {
        this.management = { ...this.management, ...{ type_id: this.$route.params.id } }
        // eslint-disable-next-line no-unused-vars
        addManagement(this.management).then(res => {
          this.$emit('addManagement')
          this.modalActive = false
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
